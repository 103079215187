import BootstrapTable, { BootstrapTableProps } from 'react-bootstrap-table-next';
import { PaginationModel } from '@/Interfaces/pagination';
import '@/components/table/table.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import _ from 'lodash';

// import Pagination from '@/components/table/pagination';
import { number } from 'yup';
import CustomPagination from '@/components/table/customPagination';

interface CustomTableProps extends BootstrapTableProps {
  paginationData?: PaginationModel<any>;
  setPaginationChangePage?: (page: number) => void;
}

const Table: React.FC<CustomTableProps> = (props: CustomTableProps) => {
  const clone = {
    ...props,
    bordered: false
  };

  return (
    <>
      <BootstrapTable rowStyle={{ height: '75px' }} {...clone} />
      {_.isUndefined(props.paginationData) ? (
        <></>
      ) : (
        <div className="pagination-content">
          <CustomPagination
            page={props.paginationData.page}
            pageSize={props.paginationData.pageSize}
            total={props.paginationData.total}
            setChangePage={props.setPaginationChangePage ?? number}
          />
        </div>
      )}
    </>
  );
};

export default Table;
