import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import Navbar from '@/components/navbar/navbar';
import Sidebar, { SidebarProps } from '@/components/sidebar/sidebar';

import 'react-datepicker/dist/react-datepicker.css';

function MainLayout() {
  const APP_VERSION = '1.0.0';
  const { i18n } = useTranslation();
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const language = localStorage.getItem('language') ?? 'th';

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n]);

  const toggleSidebar = (): void => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const sidebarProps: SidebarProps = {
    appVersion: APP_VERSION,
    isSidebarCollapsed
  };

  return (
    <>
      <Navbar
        toggleSidebar={toggleSidebar}
      />
      <div className="app">
        <Sidebar {...sidebarProps} />
        <div className="app-container">
          <Outlet />
        </div>
      </div>
    </>
  );
}
export default MainLayout;
