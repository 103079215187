import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);

export const getTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const toUtc = (date: Date) => {
  return dayjs(date).tz(getTimeZone()).utc().toDate();
};

export const toLocal = (date: Date) => {
  return dayjs.utc(date).tz(getTimeZone());
}

export const newDateWithFormat = (
  date: Date | string,
  format: string = 'DD/MM/YYYY - HH:mm:ss'
): string => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return dayjs.utc(date).tz(timezone).local().format(format);
};
