import { SvgIconTypeMap } from '@mui/material/SvgIcon';

// **** Just copy import from this repository in the link below. ****
// https://mui.com/material-ui/material-icons/
// ******************************************************************
import { OverridableComponent } from '@mui/material/OverridableComponent';

// React-icons
// https://react-icons.github.io/react-icons
import { BsDashLg, BsHouseDoor, BsPeople } from 'react-icons/bs';
import { GiGreenhouse } from 'react-icons/gi';
import { FaRegLightbulb } from 'react-icons/fa';

// We can also add custom SVG here...
import { IconType } from 'react-icons';
import { PATH } from '@/constants/path';

export interface ISidebarItem {
  path?: string;
  text: string;
  icon?: string | IconType | OverridableComponent<SvgIconTypeMap>;
  isgroup: boolean;
  group: ISidebarItem[];
}

export const SIDEBAR_ITEMLIST: ISidebarItem[] = [
  {
    path: PATH.HOME,
    text: 'MENU_HOME',
    icon: BsHouseDoor,
    isgroup: false,
    group: []
  },
  {
    path: PATH.PRODUCT_KNOWHOW,
    text: 'MENU_PRODUCT_KNOWHOW',
    icon: FaRegLightbulb,
    isgroup: false,
    group: []
  },
  {
    path: PATH.GREENHOUSE_MANGEMENT,
    text: 'MENU_GREENHOUSE',
    icon: GiGreenhouse,
    isgroup: false,
    group: []
  },
  {
    text: 'MENU_USER_MANAGEMENT',
    icon: BsPeople,
    isgroup: true,
    group: [
      {
        path: PATH.ADMIN_ACCOUNT_MANAGEMENT,
        text: 'MENU_ADMIN_ACCOUNT_MANAGEMENT',
        icon: BsDashLg,
        isgroup: false,
        group: []
      },
      {
        path: PATH.USER_ACCOUNT_MANAGEMENT,
        text: 'MENU_USER_ACCOUNT_MANAGEMENT',
        icon: BsDashLg,
        isgroup: false,
        group: []
      },
    ]
  }
];
