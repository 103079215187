import { FC } from 'react';
import { Form } from 'react-bootstrap';
import { FieldError, UseFormRegister } from 'react-hook-form';

interface TextInputInterface {
  fieldName: string;
  register: UseFormRegister<any>;
  errors: FieldError | undefined;
  isRequired: boolean;
  placeholder?: string;
}

const TextInput: FC<TextInputInterface> = ({
  fieldName,
  register,
  errors,
  isRequired,
  placeholder
}): JSX.Element => (
  <>
    <Form.Control
      {...register(fieldName, {
        required: isRequired
      })}
      className="text-box"
      type="text"
      placeholder={placeholder}
      isInvalid={!!errors}
    />

    <Form.Control.Feedback className="d-flex justify-content-start" type="invalid">
      {errors?.message && errors.message}
    </Form.Control.Feedback>
  </>
);

export default TextInput;
