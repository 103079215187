// import Table from '@/components/table/table';
import { MockUpUserInterface, MockupUsers } from '@/constants/userManagement';
import { Button, Container } from 'react-bootstrap';
import { ColumnDescription } from 'react-bootstrap-table-next';
import MoreMenuItem, { DeleteIcon, EditIcon } from '@/components/table/moreMenu/moreItem';
import MoreMenu from '@/components/table/moreMenu/moreMenu';
import { ReactNode, useEffect, useState } from 'react';
import SearchBox from '@/components/searchBox/searchBox';
import useDebounce from '@/hooks/useDebounce';
import Table from '@/components/table/table';
import { BsPlus } from 'react-icons/bs';
import { PaginationModel } from '@/Interfaces/pagination';
import { PATH } from '@/constants/path';
import { useNavigate } from 'react-router-dom';
import { newDateWithFormat } from '@/utils/dateTimeUtil';
import { useTranslation } from 'react-i18next';

import '@/pages/userManagement/userManagement.scss';

const UserManagement: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState<string>('');
  const [pagination] = useState<PaginationModel<MockUpUserInterface[]>>({
    data: MockupUsers,
    page: 1,
    pageSize: 25,
    total: 30
  });
  const debounceSearch = useDebounce(searchString, 2000);
  const [page, setPage] = useState<number>(1);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onEditClick = (row: MockUpUserInterface): void => {
    navigate(PATH.MANAGE_USER);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDeleteClick = (row: MockUpUserInterface): void => {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchData = (searchText: string, pageToChange: number) => {};

  const pageChange = (currentPage: number) => {
    setPage(currentPage);
    searchData(searchString, currentPage);
  };

  useEffect(() => {
    searchData(searchString, page);
  }, [debounceSearch]);

  const renderActionButton = (row: MockUpUserInterface): ReactNode => {
    return (
      <>
        <MoreMenu>
          <MoreMenuItem text="แก้ไขผู้ใช้งาน" icon={EditIcon()} onClick={() => onEditClick(row)} />
          <MoreMenuItem text="ลบผู้ใช้งาน" icon={DeleteIcon()} onClick={() => onDeleteClick(row)} />
        </MoreMenu>
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: '',
      text: '#',
      headerClasses: 'table-header-column',
      classes: 'table-column align-middle',
      align: 'start',
      headerAlign: 'start',
      headerStyle: { cursor: 'pointer', width: '120px' },
      formatter: (cell: any, row: MockUpUserInterface, index) => {
        return <span>{index + 1}</span>;
      }
    },
    {
      dataField: 'email',
      text: 'อีเมล',
      headerClasses: 'table-header-column',
      headerStyle: { cursor: 'pointer', width: '200px' },
      classes: 'table-column align-middle',
      align: 'start',
      headerAlign: 'start'
    },
    {
      dataField: 'name',
      text: 'ชื่อ',
      headerClasses: 'table-header-column',
      classes: 'table-column align-middle',
      headerStyle: { cursor: 'pointer', width: '200px' },
      align: 'start',
      headerAlign: 'start'
    },
    {
      dataField: 'role',
      text: 'สิทธิ์ผู้ใช้งาน',
      headerClasses: 'table-header-column',
      classes: 'table-column align-middle',
      headerStyle: { cursor: 'pointer', width: '120px' },
      align: 'start',
      headerAlign: 'start'
    },
    {
      dataField: 'registeredDate',
      text: 'วันที่สมัคร',
      headerClasses: 'table-header-column',
      classes: 'table-column align-middle',
      headerStyle: { cursor: 'pointer', width: '120px' },
      align: 'center',
      headerAlign: 'center',
      formatter: (cell: any, row: MockUpUserInterface) => {
        return <span>{newDateWithFormat(row.registeredDate)}</span>;
      }
    },
    {
      dataField: 'active',
      text: 'สถานะ',
      headerClasses: 'table-header-column',
      classes: 'table-column align-middle',
      align: 'center',
      headerAlign: 'center',
      headerStyle: { width: '100px' },
      formatter: (cell: any, row: MockUpUserInterface) => {
        return (
          <div>
            {row.status ? (
              <div className="active active-on">{t('ACTIVE')}</div>
            ) : (
              <div className="active active-off">{t('INACTIVE')}</div>
            )}
          </div>
        );
      }
    },
    {
      dataField: '',
      text: '',
      headerClasses: 'table-header-column',
      classes: 'table-column align-middle',
      isDummyField: true,
      align: 'center',
      headerStyle: { cursor: 'pointer', width: '100px' },
      formatter: (cell, row: MockUpUserInterface) => {
        return renderActionButton(row);
      }
    }
  ];

  const rowEvents = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onClick: (e: any, row: MockUpUserInterface, rowIndex: number) => {}
  };

  return (
    <Container className="management-wrapper">
      <div className="searchbox-wrapper">
        <SearchBox placeholder="ค้นหา" onChanged={(input: string) => setSearchString(input)} />
        <Button
          className="searchbox-wrapper__add"
          variant="primary"
          onClick={() => navigate(PATH.MANAGE_USER)}
        >
          <BsPlus />
          เพิ่มผู้ใช้งาน
        </Button>
      </div>

      <div className="table">
        <Table
          keyField="id"
          data={MockupUsers}
          columns={columns}
          paginationData={pagination}
          setPaginationChangePage={pageChange}
          rowEvents={rowEvents}
          rowStyle={{
            cursor: 'pointer'
          }}
        />
      </div>
    </Container>
  );
};

export default UserManagement;
