import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import '@/components/searchBox/searchBox.scss';

export interface SearchBoxProps {
  placeholder?: string;
  onChanged: (inputString: string) => void;
  icon?: JSX.Element;
}

const SearchBox: React.FC<SearchBoxProps> = ({ placeholder, onChanged, icon }) => {
  return (
    <div>
      <InputGroup className="search-group">
        <div className="relative">
          <Form.Control
            className="search-text-box"
            placeholder={placeholder}
            onChange={(event) => {
              onChanged(event.target.value);
            }}
          />
          <div className="icon-search-content">
            {icon ? <>{icon}</> : <BsSearch className="" />}
          </div>
        </div>
      </InputGroup>
    </div>
  );
};

export default SearchBox;
