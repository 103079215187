import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { PATH } from '@/constants/path';

export interface ProtectedRouteInterface {
  redirectPath?: string;
  children?: any;
  roleCheck: string[];
}

function ProtectedRoute({
  redirectPath = PATH.LOGIN,
  children,
  roleCheck
}: ProtectedRouteInterface): ReactElement | null {
  // const user = getUserToken();
  // const navigate = useNavigate();

  // eslint-disable-next-line no-console
  console.log(redirectPath, roleCheck);

  // TODO: uncomment when Role permission complete
  // useEffect(() => {
  //     if (!_.isNull(user) && !_.isUndefined(user)) {

  //         // let isAllow: boolean = false;

  //         // _.each(roleCheck, (role) => {
  //         //     const findRole = user.data.roles.find((check) => role === check);

  //         //     if (findRole) {
  //         //         isAllow = true;
  //         //     }
  //         // });

  //         // if (!isAllow) navigate(redirectPath);
  //     }
  // }, [roleCheck, redirectPath, user, navigate]);

  // TODO: uncomment when JWT token from backend complete
  // if (!user) {
  //     return <Navigate to={redirectPath} replace />;
  // }

  return children || <Outlet />;
}

export default ProtectedRoute;
