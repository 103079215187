import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from '@/components/routes/protectedRoute';
import { PATH } from '@/constants/path';
import MainLayout from '@/layout/mainLayout';
import PlainLayout from '@/layout/plainLayout';
import {
  GreenHouseManagement,
  Home,
  LoginPage,
  UserAccountManagement,
  AddProduct,
  ManageProduct,
  AddGreenHouse,
  AdminAccountManagement,
  AdminAccount,
  Profile
} from '@/loadable';
import UserManagement from '@/pages/userManagement/userManagement';
import ManageUser from '@/pages/userManagement/manageUser';

// Assign "Pages" to a "/route".
function Routes() {
  return (
    <Switch>
      {/* Protected Routes by role */}
      <Route
        element={
          <ProtectedRoute
            roleCheck={
              [
                // TODO: Check role
              ]
            }
          />
        }
      >
        <Route element={<MainLayout />}>
          <Route path="/" element={<Navigate to={PATH.HOME} />} />
          <Route key={PATH.HOME} path={PATH.HOME} element={<Home />} />
          <Route
            key={PATH.PRODUCT_KNOWHOW}
            path={PATH.PRODUCT_KNOWHOW}
            element={<ManageProduct />}
          />
          <Route key={PATH.ADD_PRODUCT} path={PATH.ADD_PRODUCT} element={<AddProduct />} />
          <Route
            key={PATH.USER_MANAGEMENT}
            path={PATH.USER_MANAGEMENT}
            element={<UserManagement />}
          />
          <Route
            key={PATH.GREENHOUSE_MANGEMENT}
            path={PATH.GREENHOUSE_MANGEMENT}
            element={<GreenHouseManagement />}
          />
          <Route key={PATH.ADD_GREENHOUSE} path={PATH.ADD_GREENHOUSE} element={<AddGreenHouse />} />
          <Route key={PATH.MANAGE_USER} path={PATH.MANAGE_USER} element={<ManageUser />} />
          <Route
            key={PATH.ADMIN_ACCOUNT_MANAGEMENT}
            path={PATH.ADMIN_ACCOUNT_MANAGEMENT}
            element={<AdminAccountManagement />}
          />
          <Route key={PATH.ADMIN_ACCOUNT} path={PATH.ADMIN_ACCOUNT} element={<AdminAccount />} />
          <Route
            key={PATH.USER_ACCOUNT_MANAGEMENT}
            path={PATH.USER_ACCOUNT_MANAGEMENT}
            element={<UserAccountManagement />}
          />
          <Route key={PATH.PROFILE} path={PATH.PROFILE} element={<Profile />} />
        </Route>
      </Route>

      {/* Public Routes */}
      <Route element={<PlainLayout />}>
        <Route key={PATH.LOGIN} path={PATH.LOGIN} element={<LoginPage />} />
      </Route>
    </Switch>
  );
}

export default Routes;
