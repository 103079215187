import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '@/localization/config';
import Toast from '@/components/toast';
import Routes from '@/routes';

import '@/scss/main.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { LoaderProvider } from '@/context/loaderContext';
import { FirebaseProvider } from '@/utils/filebaseIndex';

function App(): JSX.Element {
  return (
    <FirebaseProvider>
      <div>
        <LoaderProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Solar Greenhouse Dryer</title>
          </Helmet>
          {/* We use BrowserRouter specifically because Router is on a higher-level and it will implicitly use one the implementations of Router by default anyway */}
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
          <Toast />
        </LoaderProvider>
      </div>
    </FirebaseProvider>
  );
}

export default App;
