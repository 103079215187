import classNames from 'classnames';
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowLeft } from 'react-icons/md';

export interface ArrowButtonProps {
  isDisabled?: boolean;
  arrow?: 'left' | 'right';
  handleOnclick: () => void;
}

const ArrowButton: React.FC<ArrowButtonProps> = ({
  isDisabled = false,
  arrow = 'right',
  handleOnclick
}): JSX.Element => {
  const renderArrow = (): JSX.Element => {
    if (arrow === 'right') {
      return <MdOutlineKeyboardArrowRight />;
    }

    if (arrow === 'left') {
      return <MdOutlineKeyboardArrowLeft />;
    }

    return <></>;
  };

  const removeButtonBorder = () => {
    return arrow === 'right' ? { borderLeft: 0 } : { borderRight: 0 };
  };

  return (
    <>
      <button
        type="button"
        className={classNames(`arrow__btn ${isDisabled ? ' arrow__btn__disable' : ''}`)}
        onClick={() => handleOnclick()}
        disabled={isDisabled}
        style={removeButtonBorder()}
      >
        {renderArrow()}
      </button>
    </>
  );
};

export default ArrowButton;
