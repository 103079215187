export enum UserRoleEnum {
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  STAFF = 'staff'
}

export const MockupUsers: MockUpUserInterface[] = [
  {
    id: 1,
    email: 'pmate@gmail.com',
    name: 'P muay',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 2,
    email: 'paong@gmail.com',
    name: 'P Aong',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: false
  },
  {
    id: 3,
    email: 'arty@gmail.com',
    name: 'Nong Art sud lor tae',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: false
  },
  {
    id: 4,
    email: 'filmtap@gmail.com',
    name: 'Flimy gimegime love',
    role: UserRoleEnum.ADMIN,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 5,
    email: 'kimmymy@gmail.com',
    name: 'Nong kimmy aroi',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 6,
    email: 'ying@gmail.com',
    name: 'Nong Ying 666',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 7,
    email: 'chalermdej@gmail.com',
    name: 'P deow top 100',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 8,
    email: 'pmate@gmail.com',
    name: 'P muay',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 9,
    email: 'paong@gmail.com',
    name: 'P Aong',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: false
  },
  {
    id: 10,
    email: 'arty@gmail.com',
    name: 'Nong Art sud lor tae',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: false
  },
  {
    id: 11,
    email: 'filmtap@gmail.com',
    name: 'Flimy gimegime love',
    role: UserRoleEnum.ADMIN,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 12,
    email: 'kimmymy@gmail.com',
    name: 'Nong kimmy aroi',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 13,
    email: 'ying@gmail.com',
    name: 'Nong Ying 666',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 14,
    email: 'chalermdej@gmail.com',
    name: 'P deow top 100',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 15,
    email: 'pmate@gmail.com',
    name: 'P muay',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 16,
    email: 'paong@gmail.com',
    name: 'P Aong',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: false
  },
  {
    id: 17,
    email: 'arty@gmail.com',
    name: 'Nong Art sud lor tae',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: false
  },
  {
    id: 18,
    email: 'filmtap@gmail.com',
    name: 'Flimy gimegime love',
    role: UserRoleEnum.ADMIN,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 19,
    email: 'kimmymy@gmail.com',
    name: 'Nong kimmy aroi',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 20,
    email: 'ying@gmail.com',
    name: 'Nong Ying 666',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 21,
    email: 'chalermdej@gmail.com',
    name: 'P deow top 100',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 22,
    email: 'pmate@gmail.com',
    name: 'P muay',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 23,
    email: 'paong@gmail.com',
    name: 'P Aong',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: false
  },
  {
    id: 24,
    email: 'arty@gmail.com',
    name: 'Nong Art sud lor tae',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: false
  },
  {
    id: 25,
    email: 'filmtap@gmail.com',
    name: 'Flimy gimegime love',
    role: UserRoleEnum.ADMIN,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 26,
    email: 'kimmymy@gmail.com',
    name: 'Nong kimmy aroi',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 27,
    email: 'ying@gmail.com',
    name: 'Nong Ying 666',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 28,
    email: 'chalermdej@gmail.com',
    name: 'P deow top 100',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 29,
    email: 'ying@gmail.com',
    name: 'Nong Ying 666',
    role: UserRoleEnum.STAFF,
    registeredDate: new Date(),
    status: true
  },
  {
    id: 30,
    email: 'chalermdej@gmail.com',
    name: 'P deow top 100',
    role: UserRoleEnum.SUPER_ADMIN,
    registeredDate: new Date(),
    status: true
  }
];

export interface UserRoleDetail {
  id: number;
  name: string;
}

export const MockUpUserRole: UserRoleDetail[] = [
  {
    id: 1,
    name: UserRoleEnum.SUPER_ADMIN
  },
  {
    id: 2,
    name: UserRoleEnum.ADMIN
  },
  {
    id: 3,
    name: UserRoleEnum.STAFF
  }
];

export interface MockUpUserInterface {
  id: number;
  email: string;
  name: string;
  role: UserRoleEnum;
  registeredDate: Date;
  status: boolean;
}
