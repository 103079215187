import { FC, forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import burgerMenu from '@/assets/svg/hamburger_menu.svg';
import '@/components/navbar/navbar.scss';
import { BsBoxArrowInRight, BsChevronDown, BsPerson } from 'react-icons/bs';
import { PATH } from '@/constants/path';
import { useNavigate } from 'react-router-dom';
import { getUserToken } from '@/utils/authUtil';

export interface NavbarProps {
  toggleSidebar: () => void;
}

const Navbar: FC<NavbarProps> = (props: NavbarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = getUserToken();

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
  };

  const CustomToggle = forwardRef(
    (customToggleProps: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            customToggleProps.onClick(e);
          }}
        >
          {customToggleProps.children}
        </a>
      );
    }
  );

  const handleLogout = async (): Promise<void> => {
    await localStorage.removeItem('token');
    await localStorage.removeItem('refresh-token');
    window.location.href = PATH.LOGIN;
  };

  return (
    <div className="navbar">
      <div className="container">
        <div className="container__left">
          <div className="hamburger-menu" onClick={() => props.toggleSidebar()}>
            <SVG src={burgerMenu} />
          </div>
          <img className='navbar-logo' src='/logo.svg' alt='' />
        </div>

        <div className="container__center">
          <span className="text-subtitle1">{t('SOLAR_GREENHOUSE_ADMIN')}</span>
        </div>

        <div className="container__right">

          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="user-profile-toggle">
              <button type="button" className="button-user-name">
                <span className="user-name-navbar">{user?.name ?? ""}</span>
                <BsChevronDown />
              </button>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="ms-1" onClick={() => navigate(PATH.PROFILE)} href="#">
                <BsPerson />
                <span className="ms-2">{t('PROFILE')}</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleLogout()}>
                <BsBoxArrowInRight />
                <span className="ms-2">{t('LOGOUT')}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
