import ArrowButton from '@/components/table/arrowButton';
import { PaginationInterface } from '@/Interfaces/pagination';
import _ from 'lodash';
import { FC, ReactNode } from 'react';
import '@/components/table/customPagination.scss';
import classNames from 'classnames';
import { HiChevronDoubleRight, HiChevronDoubleLeft } from 'react-icons/hi';

interface PaginationProps extends PaginationInterface {
  setChangePage: (page: number) => void;
}

const CustomPagination: FC<PaginationProps> = ({ page, pageSize, total, setChangePage }) => {
  const lastPage = Math.ceil(total / pageSize);
  const onClickNext = (): void => {
    if (!_.isUndefined(page)) {
      const nextPage = page + 1;
      setChangePage(nextPage);
    }
  };

  const onClickBack = () => {
    if (!_.isUndefined(page)) {
      const previousPage = page - 1;
      setChangePage(previousPage);
    }
  };

  const onChangePage = (paginationPage: number, currentPage: number): void => {
    if (currentPage !== paginationPage) {
      setChangePage(paginationPage);
    }
  };

  const PaginationItem = ({
    children,
    currentPage,
    pageIndex
  }: {
    children: ReactNode;
    currentPage: number;
    pageIndex: number;
  }): JSX.Element => {
    return (
      <button
        type="button"
        className={`item__wrapper__item ${currentPage === pageIndex ? 'item__active' : ''}`}
        onClick={() => onChangePage(pageIndex, currentPage)}
      >
        {children}
      </button>
    );
  };

  const renderPage = (): JSX.Element | void => {
    const paginationList: JSX.Element[] = [];

    if (page <= 5) {
      const findLastPage = lastPage <= 5 ? lastPage : 5;

      for (let i = 1; i <= findLastPage; i++) {
        paginationList.push(
          <PaginationItem currentPage={page} pageIndex={i} key={i}>
            {i}
          </PaginationItem>
        );
      }
    } else {
      for (let i = page - 2; i <= page + 2; i++) {
        if (i <= lastPage) {
          paginationList.push(
            <PaginationItem currentPage={page} pageIndex={i} key={i}>
              {i}
            </PaginationItem>
          );
        }
      }
    }

    return <div className="item__wrapper">{paginationList}</div>;
  };

  const onClickGoToFistPage = () => {
    setChangePage(1);
  };

  const onClickGoToLastPage = () => {
    setChangePage(lastPage);
  };

  return (
    <div className="pagination__layout">
      <button
        type="button"
        className={classNames(
          `arrow__btn__firstpage ${page === 1 ? ' arrow__btn__firstpage--disable' : ''}`
        )}
        onClick={() => onClickGoToFistPage()}
        disabled={page === 1}
      >
        <HiChevronDoubleLeft />
      </button>
      <ArrowButton arrow="left" handleOnclick={onClickBack} isDisabled={page === 1} />
      <>{renderPage()}</>
      <ArrowButton arrow="right" handleOnclick={onClickNext} isDisabled={page === lastPage} />
      <button
        type="button"
        className={classNames(
          `arrow__btn__lastpage ${page === lastPage ? ' arrow__btn__lastpage--disable' : ''}`
        )}
        onClick={() => onClickGoToLastPage()}
        disabled={page === lastPage}
      >
        <HiChevronDoubleRight />
      </button>
    </div>
  );
};

export default CustomPagination;
