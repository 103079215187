import { FC, Key } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsReply, BsToggles, BsPencilSquare, BsTrash } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';

export interface MoreMenuItemProps {
  key?: Key | null | undefined;
  text?: string;
  icon?: JSX.Element;
  onClick?: () => void;
}

const MoreMenuItem: FC<MoreMenuItemProps> = ({ key, text, icon, onClick }) => {
  return (
    <Dropdown.Item className="more-menu-item" onClick={onClick} key={key}>
      {icon && icon}
      {text && text}
    </Dropdown.Item>
  );
};

export const DeleteIcon = () => {
  return <BsTrash />;
};

export const ReplyIcon = () => {
  return <BsReply />;
};

export const EditIcon = () => {
  return <BsPencilSquare />;
};

export const TogglesIcon = () => {
  return <BsToggles />;
};

export const ReSendIcon = () => {
  return <FiSend />;
};

export default MoreMenuItem;
