import { createContext, FC, useMemo, useState } from 'react';
import Loading from '@/components/loader/loader';

export const LoaderContext = createContext({
  isShow: false,
  showLoading: () => {},
  closeLoading: () => {}
});

export interface ILoaderProviderProps {
  children: any;
}

export const LoaderProvider: FC<ILoaderProviderProps> = ({ children }) => {
  const [isShow, setIsShow] = useState(false);
  const showLoading = (): void => {
    setIsShow(true);
  };
  const closeLoading = (): void => {
    setIsShow(false);
  };
  const value = useMemo(() => ({ isShow, showLoading, closeLoading }), [isShow]);

  return (
    <LoaderContext.Provider value={value}>
      {children}
      <Loading show={isShow} />
    </LoaderContext.Provider>
  );
};
