import { useNavigate } from 'react-router-dom';
import BreadcrumbBox, { BreadcrumbItem } from '@/components/breadcrumb/breadcrumb';
import TextInput from '@/components/textInput/textInput';
import { PATH } from '@/constants/path';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MockUpUserRole, UserRoleDetail, UserRoleEnum } from '@/constants/userManagement';
import * as yup from 'yup';

import '@/pages/userManagement/manageUser.scss';

interface IFormInput {
  email: string;
  username: string;
  role: UserRoleEnum;
}

const ManageUser = () => {
  const { t } = useTranslation();
  const schema = yup.object({
    email: yup.string().email(t('PLEASE_ENTER_VALID_EMAIL')).required(t('PLEASE_ENTER_EMAIL')),
    username: yup.string().required(t('PLEASE_ENTER_USERNAME')),
    role: yup.string()
  });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>({
    resolver: yupResolver(schema)
  });

  const getBreadcrumData = () => {
    const crumb: BreadcrumbItem[] = [
      {
        label: 'จัดการผู้ใช้งาน',
        href: PATH.USER_MANAGEMENT
      },
      {
        label: 'เพิ่มผู้ใช้งาน',
        active: true
      }
    ];

    return crumb;
  };

  const onSave = () => {

  };

  const onSelectedUserRole = (selectedRoleValue: any) => {
    // eslint-disable-next-line no-console
    console.log(selectedRoleValue);
  };

  return (
    <BreadcrumbBox items={getBreadcrumData()}>
      <Container className="manage-user">
        <Form onSubmit={handleSubmit(onSave)}>
          <Form.Group as={Row} className="mt-3" controlId="formType">
            <Form.Label column sm="2">
              {t('LOGIN_EMAIL')} :
            </Form.Label>
            <div className="input-wrapper">
              <TextInput
                register={register}
                fieldName="email"
                errors={errors.email}
                isRequired
                placeholder={t('LOGIN_EMAIL')}
              />
            </div>
          </Form.Group>

          <Form.Group as={Row} className="mt-3" controlId="formType">
            <Form.Label column sm="2">
              {t('USERNAME')} :
            </Form.Label>
            <div className="input-wrapper">
              <TextInput
                register={register}
                fieldName="username"
                errors={errors.username}
                isRequired
                placeholder={t('USERNAME')}
              />
            </div>
          </Form.Group>

          <Form.Group as={Row} className="mt-3" controlId="formType">
            <Form.Label column sm="2">
              {t('USER_ROLE')} :
            </Form.Label>
            <Col sm="4">
              <Form.Select
                {...register('role')}
                isInvalid={!!errors.role}
                onChange={(e) => onSelectedUserRole(e.target.value)}
              >
                {MockUpUserRole.map((role: UserRoleDetail) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mt-5" controlId="formAction">
            <Col sm="6">
              <div className="w-100 mb-5 d-flex gap-3 justify-content-end">
                <Button variant="outline-primary" onClick={() => navigate(PATH.USER_MANAGEMENT)}>
                  {t('CANCEL')}
                </Button>
                <Button variant="primary" type="submit">
                  {t('SAVE')}
                </Button>
              </div>
            </Col>
          </Form.Group>
        </Form>
      </Container>
    </BreadcrumbBox>
  );
};

export default ManageUser;
