import { FC, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { Nav } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import SvgIcon from '@mui/material/SvgIcon';
import { ISidebarItem } from '@/constants/sidebarItem';
// import SvgIcon, { SvgIconTypeMap } from "@mui/material/SvgIcon";
// import { OverridableComponent } from "@mui/material/OverridableComponent";
// import { IconType, GenIcon } from "react-icons";
// import React from "react";

const ItemLink: FC<ISidebarItem> = ({ path, text, group, icon }): JSX.Element => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const itemKey = `${path ?? ''} ${text}`;
  const { t } = useTranslation();

  const isSelected = pathname === path; // TODO => Fix this

  const renderGroupArrowIcon = (sidebarItems: ISidebarItem[]): JSX.Element | undefined => {
    if (sidebarItems.length > 0) {
      return show ? (
        <KeyboardArrowUpIcon className="side__group_arrow" />
      ) : (
        <KeyboardArrowDownIcon className="side__group_arrow" />
      );
    }

    return undefined;
  };

  const renderIcon = (iconToRender?: any): JSX.Element => {
    if (iconToRender) {
      if (
        iconToRender.type !== undefined &&
        iconToRender.type.render !== undefined &&
        iconToRender.type.render.muiName !== undefined
      ) {
        // Material UI icons
        return <SvgIcon component={iconToRender} />;
      }
      if (typeof icon === 'string') {
        // Custom icons with source passed as string.
        return <SVG src={icon} />;
      }
      if (typeof icon === 'function') {
        // React-icons >> https://react-icons.github.io/react-icons <<
        return iconToRender();
      }

      if (typeof icon === 'object') {
        return <SvgIcon component={iconToRender} />;
      }

      // Already a JSX Element
      return iconToRender;
    }

    // Not specified - no icon -
    return <div />;
  };

  useEffect(() => {
    if (isSelected) {
      document.title = t(text);
    }
  }, [isSelected, t, text]);

  const linkAction = (pathAction: string | undefined): void => {
    // TODO : check permission before push
    if (pathAction) {
      document.title = t(text);
      navigate(pathAction);
    }
  };

  return (
    <>
      <Nav.Item
        key={itemKey}
        className={isSelected ? 'selected' : ''}
        onClick={() => setShow(!show)}
      >
        <Nav.Link eventKey={itemKey} className="sidebar-content" onClick={() => linkAction(path)}>
          {renderIcon(icon)}
          <span>{t(text)}</span>
        </Nav.Link>
        {renderGroupArrowIcon(group)}
      </Nav.Item>
      {show &&
        group?.length > 0 &&
        group.map((item) => {
          const selecedChild = item.path === pathname;
          return (
            <Nav.Item key={itemKey} className={selecedChild ? 'selected' : ''}>
              <Nav.Link
                eventKey={itemKey}
                className="sidebar-content side__group_item"
                onClick={() => linkAction(item.path)}
              >
                <span>{t(item.text)}</span>
              </Nav.Link>
            </Nav.Item>
          );
        })}
    </>
  );
};

export default ItemLink;
