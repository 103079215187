export enum PATH {
  HOME = '/home',
  PRODUCT_KNOWHOW = '/manage-product',
  ADD_PRODUCT = '/add-product',
  GREENHOUSE_MANGEMENT = '/greenhouse-management',
  ADD_GREENHOUSE = '/add-greenhouse',
  USER_MANAGEMENT = '/user-management',
  ADMIN_ACCOUNT_MANAGEMENT = '/admin-account-management',
  ADMIN_ACCOUNT = '/admin-account',
  USER_ACCOUNT_MANAGEMENT = '/user-account-management',
  LOGIN = '/login',
  MANAGE_USER = '/manage-user',
  PROFILE = '/profile'
}

export default PATH;