import 'react-datepicker/dist/react-datepicker.css';
import { Outlet } from 'react-router-dom';

function PlainLayout() {
  return (
    <div className="app">
      <Outlet />
    </div>
  );
}
export default PlainLayout;
