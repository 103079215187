import Loadable from 'react-loadable';
import Loading from '@/components/loading';

const LoadableTestPlan = Loadable({
  loader: async () => import('@/pages/testPlan'),
  loading: Loading
});

const LoadableHome = Loadable({
  loader: async () => import('@/pages/HomePage/homePage'),
  loading: Loading
});

const LoadableDashboard = Loadable({
  loader: async () => import('@/pages/home'),
  loading: Loading
});

const LoadableLogin = Loadable({
  loader: async () => import('@/pages/loginPage/loginPage'),
  loading: Loading
});

const LoadableModal = Loadable({
  loader: async () => import('react-bootstrap/Modal'),
  loading: Loading
});

const LoadableUserManagement = Loadable({
  loader: async () => import('@/pages/userManagement/userManagement'),
  loading: Loading
});

const LoadableManageUser = Loadable({
  loader: async () => import('@/pages/userManagement/manageUser'),
  loading: Loading
});

const LoadableGreenHouseManagement = Loadable({
  loader: async () => import('@/pages/greenhouseManagementPage/greenhouseManagementPage'),
  loading: Loading
});

const LoadableAddGreenHouse = Loadable({
  loader: async () => import('@/pages/greenhouseManagementPage/addGreenhousePage'),
  loading: Loading
});

const LoadableManageProduct = Loadable({
  loader: async () => import('@/pages/manageProductPage/manageProductPage'),
  loading: Loading
});

const LoadableAddProduct = Loadable({
  loader: async () => import('@/pages/manageProductPage/addProductPage'),
  loading: Loading
});

const LoadableAdminAccountManagement = Loadable({
  loader: async () => import('@/pages/adminAccountManagementPage/adminAccountManagementPage'),
  loading: Loading
});

const LoadableAdminAccount = Loadable({
  loader: async () => import('@/pages/adminAccountManagementPage/adminAccountPage'),
  loading: Loading
});

const LoadableUserAccountManagement = Loadable({
  loader: async () => import('@/pages/userAccountManagementPage/userAccountManagementPage'),
  loading: Loading
});

const LoadableProfile = Loadable({
  loader: async () => import('@/pages/profilePage/profilePage'),
  loading: Loading
});

export const TestPlan = LoadableTestPlan;
export const Home = LoadableHome;
export const Dashboard = LoadableDashboard;
export const ModalDialog = LoadableModal;
export const LoginPage = LoadableLogin;
export const UserManagement = LoadableUserManagement;
export const ManageUser = LoadableManageUser;
export const GreenHouseManagement = LoadableGreenHouseManagement;
export const AddGreenHouse = LoadableAddGreenHouse;
export const ManageProduct = LoadableManageProduct;
export const AddProduct = LoadableAddProduct;
export const AdminAccountManagement = LoadableAdminAccountManagement;
export const AdminAccount = LoadableAdminAccount;
export const UserAccountManagement = LoadableUserAccountManagement;
export const Profile = LoadableProfile;
