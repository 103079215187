import '@/components/loader/loader.scss';

export interface LoaderProps {
  show: boolean;
}
const Loading: React.FC<LoaderProps> = ({ show }) => (
  <div className={show ? '' : 'd-none'}>
    <div className="container-loading  m-auto">
      <div className="background" />
      <div className="container-card">
        <div className="spinner-border text-primary mb-2" role="status" />
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  </div>
);

export default Loading;
